import request from '@/pages/iebdc/utils/request';
import { REST_SERVICE } from '@/pages/iebdc/config';
const getlpbInfo = {
  //提取楼盘表
  getlpbInfo(params) {
    return request({
      url: REST_SERVICE.apply.getlpbInfo,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: params,
    });
  },
  // 通过宗地代码获取所有自然幢号
  getFwxxByZddm(params) {
    return request({
      url: REST_SERVICE.apply.getFwxxByZddm,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      params,
    });
  },
  getZlist(params) {
    return request({
      url: REST_SERVICE.apply.getZlist,
      method: 'post',
      params,
    });
  },
  getHlist(params) {
    return request({
      url: REST_SERVICE.apply.getHList,
      method: 'post',
      params,
    });
  },
};

export default getlpbInfo;
