<template>
  <div class="house-box lpb" v-if="showlpb">
    <!--图标-->
    <div class="house-legend">
      <span class="house-legend-icon">图例：</span>
      <span
        v-for="item in legendData"
        :key="item.name"
        class="legend-item"
        :style="{ 'background-color': item.color }"
        >{{ item.name }}</span
      >
      <div class="checkbox-label">
        <span class="house-legend-icon lh-26">详情：</span>
        <po-checkbox :checkboxData="detailsCheckbox" @change="checkChangeMj" class="select-show" />
      </div>
    </div>
    <div class="house-xmxx">
      <span
        >全选/全不选
        <el-checkbox
          class="checkbox-margin"
          v-model="selectedAllOrWithout"
          @change="checkHang(selectedAllOrWithout, selectedAllOrWithout ? '全选' : '全不选')"
        />
      </span>
      <!--<span class="left">项目名称 : xxx</span>-->
      <span class="left">坐落 : {{ zl }}</span>
    </div>
    <!--楼盘表-->
    <el-scrollbar class="house-lpb">
      <table class="house-table" v-if="sumRowData.length !== 0">
        <thead>
          <tr>
            <th class="gudinglie house-th">实际层</th>
            <th class="gudinglie house-th">名义层</th>
            <th
              v-if="colspanArr.length > 0"
              class="gudinglie house-th"
              v-for="(item, key) in colspanArr"
              :key="key"
              :colspan="item.maxHu"
            >
              {{ item.dyh }}单元
            </th>
            <th v-if="!colspanArr.length"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in sumRowData" :key="key">
            <td class="gudinglie">{{ isNaN(item.sjc) ? item.sjc : `第${item.sjc}层` }}</td>
            <td class="gudinglie">
              <el-checkbox v-model="item.checked" :disabled="disable" @change="checkHang(item, '住宅区')"></el-checkbox>
              {{ isNaN(item.sjc) ? item.sjc : `第${item.sjc}层` }}
            </td>
            <td
              class="gudinglie house-td"
              v-for="(subitem, index) in item.data"
              :key="index"
              style="padding: 0"
              :style="{
                background: subitem.color ? subitem.color : '',
                border: subitem.border ? '2px solid red!important' : '0',
              }"
            >
              <div
                :style="{
                  border:
                    containHtxx.length > 0 && containHtxx.indexOf(subitem.fwid) >= 0
                      ? '2px solid #f0ff00!important'
                      : '',
                }"
              >
                <div
                  class="lpb-content"
                  :class="[
                    subitem.updateSize === true || subitem.upper === true ? 'content-size' : '',
                    subitem.isChoice === true ? 'hignLightStyle' : '',
                  ]"
                >
                  <div class="dis-label" v-if="JSON.stringify(subitem) !== '{}'">
                    <el-checkbox
                      :class="subitem.isDisabled ? 'el-checkbox-style' : 'checkbox-margin'"
                      v-model="subitem.checked"
                      :disabled="subitem.isDisabled"
                      @change="checkHouse(subitem, `住宅区${subitem.sjc}层${subitem.shbw}`)"
                    />
                    <span class="shbw"> {{ subitem.shbw }}</span>
                    <span v-if="subitem.status === 'IN_PROCESS'" class="shbw-status"> 在办</span>
                    <i
                      v-if="subitem != null"
                      class="icon el-icon-warning"
                      title="查看不动产单元信息"
                      @click="viewinfofun(subitem)"
                    ></i>
                  </div>
                  <!-- 添加对应流程标识 -->
                  <div class="dis-label-lcbs">
                    <div class="lcbs" v-if="subitem.extStatus ? true : false">
                      <span v-if="subitem.isBdcdybaType === '1'">冻结</span>
                      <span v-if="subitem.sfyxtdz === '1'">土</span>
                      <span v-if="subitem.isCfbaType === '1'">备案</span>
                      <span v-if="subitem.extStatus.sfyg === 1">预</span>
                      <span v-if="subitem.extStatus.sfygdy === 1">预抵</span>
                      <span v-if="subitem.extStatus.sfbdc === 1 && subitem.djlx !== '0102' && subitem.djlx !== '0106'"
                        >首产</span
                      >
                      <span v-if="subitem.extStatus.sfbdc === 1 && (subitem.djlx === '0102' || subitem.djlx === '0106')"
                        >产转</span
                      >
                      <span v-if="subitem.extStatus.sfdya === 1">抵</span>
                      <span v-if="subitem.extStatus.sfcf === 1">查</span>
                    </div>
                  </div>
                  <div class="label-show" v-if="subitem.jzmj && showJZMJ">
                    <span>实测建筑面积：</span><span>{{ subitem.jzmj }}</span
                    ><span>㎡</span>
                  </div>
                  <div class="label-show" v-if="subitem.ycjzmj >= 0 && showYCJZMJ">
                    <span>预测建筑面积：</span><span>{{ subitem.ycjzmj }}</span
                    ><span>㎡</span>
                  </div>
                  <div class="label-show" v-if="subitem.tnjzmj >= 0 && showTNMJ">
                    <span>套内面积：</span><span>{{ subitem.tnjzmj }}</span
                    ><span>㎡</span>
                  </div>
                  <div class="label-show" v-if="subitem.ghyt && showFWYT">
                    <span>房屋用途：</span><dict-span type="房屋用途" :binddata="subitem.ghyt"></dict-span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="house-table" v-if="noSumRowData.length !== 0">
        <thead>
          <tr>
            <th class="gudinglie house-th">实际层</th>
            <th class="gudinglie house-th">名义层</th>
            <th v-if="noMaxColspan > 0" class="gudinglie house-th" :colspan="noMaxColspan"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in noSumRowData" :key="key">
            <td class="gudinglie">第{{ isNaN(item.sjc) ? ' ' : item.sjc }}层</td>
            <td class="gudinglie">
              1
              <el-checkbox
                v-model="item.checked"
                :disabled="disable"
                @change="checkHang(item, '非住宅区')"
              ></el-checkbox>
              第{{ isNaN(item.sjc) ? ' ' : item.sjc }}层
            </td>
            <td
              class="gudinglie house-td"
              v-for="(subitem, index) in item.data"
              :key="index"
              style="padding: 0"
              :style="{
                background: subitem.color ? subitem.color : '',
                border: subitem.border ? '2px solid red!important' : '0',
              }"
            >
              <!--<div-->
              <!--v-if="subitem && subitem.houseLegend"-->
              <!--style="padding: 8px;"-->
              <!-- :class="{ 'color-white': !!subitem.houseLegend.color }"-->
              <!-- :title="subitem.shbw"-->
              <!--              >-->
              <div
                :style="{
                  border: containHtxx.length > 0 && containHtxx.indexOf(subitem.fwid) >= 0 ? '2px solid #f0ff00;' : '',
                }"
              >
                <div class="lpb-content">
                  <div class="dis-label">
                    <el-checkbox
                      :class="subitem.isDisabled ? 'el-checkbox-style' : 'checkbox-margin'"
                      v-model="subitem.checked"
                      :disabled="subitem.isDisabled"
                      @change="checkHouse(subitem, `非住宅区${subitem.sjc}层${subitem.shbw}`)"
                    />
                    <span class="shbw"> {{ subitem.shbw }}</span>
                    <span v-if="subitem.status === 'IN_PROCESS'" class="shbw-status"> 在办</span>
                    <i
                      v-if="subitem != null"
                      class="icon el-icon-warning"
                      title="查看不动产单元信息"
                      @click="viewinfofun(subitem)"
                    ></i>
                  </div>
                  <!-- 添加对应流程标识 -->
                  <div class="dis-label-lcbs">
                    <div class="lcbs" v-if="subitem.extStatus ? true : false">
                      <span v-if="subitem.isBdcdybaType === '1'">冻结</span>
                      <span v-if="subitem.sfyxtdz === '1'">土</span>
                      <span v-if="subitem.isCfbaType === '1'">备案</span>
                      <span v-if="subitem.extStatus.sfyg === 1">预</span>
                      <span v-if="subitem.extStatus.sfygdy === 1">预抵</span>
                      <span v-if="subitem.extStatus.sfbdc === 1 && subitem.djlx !== '0102' && subitem.djlx !== '0106'"
                        >首产</span
                      >
                      <span v-if="subitem.extStatus.sfbdc === 1 && (subitem.djlx === '0102' || subitem.djlx === '0106')"
                        >产转</span
                      >
                      <span v-if="subitem.extStatus.sfdya === 1">抵</span>
                      <span v-if="subitem.extStatus.sfcf === 1">查</span>
                    </div>
                  </div>
                  <div class="label-show" v-if="subitem.jzmj && showJZMJ">
                    <span>实测建筑面积：</span><span>{{ subitem.jzmj }}</span
                    ><span>㎡</span>
                  </div>
                  <div class="label-show" v-if="subitem.ycjzmj >= 0 && showYCJZMJ">
                    <span>预测建筑面积：</span><span>{{ subitem.ycjzmj }}</span
                    ><span>㎡</span>
                  </div>
                  <div class="label-show" v-if="subitem.tnjzmj >= 0 && showTNMJ">
                    <span>套内面积：</span><span>{{ subitem.tnjzmj }}</span
                    ><span>㎡</span>
                  </div>
                  <div class="label-show" v-if="subitem.ghyt && showFWYT">
                    <span>房屋用途：</span><dict-span type="房屋用途" :binddata="subitem.ghyt"></dict-span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </el-scrollbar>
    <UnitHouse
      :dialogUnitInfo="dialogUnitInfo"
      :houseDetailsInfo="houseDetailsInfo"
      @dialogBeforeClose="dialogBeforeClose"
    />
  </div>
</template>

<script>
// import { lpbData } from './lpbData';
import PoCheckbox from './PoCheckbox';
import UnitHouse from './UnitHouse';
import { mapState, mapGetters } from 'vuex';

export default {
  // 注意：console.log测试的i,k值是从低层到高层排的，后来改成从高层到底层
  name: 'lpb',
  components: { UnitHouse, PoCheckbox },
  props: {
    //楼盘表数据
    arrData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    containHtxx: {
      type: Array,
      default: () => {
        return [];
      },
    },

    //是否显示楼盘表
    showlpb: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    //图例
    legendData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    //详情
    detailsCheckbox: {
      type: Object,
      default: () => {
        return {};
      },
    },
    //是否禁用
    disable: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    //查询参数 不动产权证号/证明号
    checkBdcqzh: {
      type: String,
      default: () => {
        return '';
      },
    },
    //下一步按钮状态
    nextBtnState: {
      type: Function,
    },
  },
  computed: {
    // 选择的流程类型信息
    ...mapState('wysq-data', ['firstFlow']),
    ...mapGetters(['selectedOrganizationsCode']),
  },
  data() {
    return {
      zl: '', //项目名称
      selectedHouseSum: [], //最终勾选的所有户数据
      //假数据-住宅区和非住宅区数据
      // arrData: lpbData,
      unitHouseData: [], //住宅区数据
      finalZhData: [], //单元号的集合;升序
      sjcArr: [], //住宅区-实际的层集合
      maxHuArr: [], //算出每个单元，每一层最多有多少户
      maxHu: 0, //每层户数的最大值
      colspanArr: [], //th数据，单元号和最终每个单元的跨列数
      sumRowData: [], //tbody数据集合，组织后的数据结构，最终要的所有层的数据
      //每一层数据
      rowData: {
        sjc: '',
        data: [], //该层所有户的数据
      },
      temporary: [], //暂存，拿到第n层，第m单元的所有户

      noUnitHouseData: [], //非住宅区数据
      nosjcArr: [], //非住宅区-实际的层集合
      noMaxColspan: 0, //非住宅区-th的最大跨列数
      noSumRowData: [], //tbody数据集合，组织后的数据结构，最终要的所有层的数据
      noRowData: {
        sjc: '',
        data: [], //该层所有户的数据
      },
      //户详情信息
      dialogUnitInfo: false,
      houseDetailsInfo: {}, //当前户详情信息
      //全选/全不选标识
      selectedAllOrWithout: false,
      //图标
      showJZMJ: false,
      showYCJZMJ: false,
      showTNMJ: false,
      showFWYT: false,
    };
  },
  watch: {
    arrData: {
      handler(val) {
        // console.log(val, 'arrData');
        let state = val.some((item) => {
          return item.hasOwnProperty('checked') && item.checked;
        });
        this.$emit('nextBtnState', !state);
      },
      deep: true,
    },
  },
  methods: {
    init(type, zl) {
      this.zl = zl;
      //初始化处理
      this.unitHouseData = [];
      this.noUnitHouseData = [];
      this.sumRowData = [];
      this.noSumRowData = [];
      this.colspanArr = [];
      if (type === 1) return;
      const lzyzLimit = this.selectedOrganizationsCode === '620123'; // 兰州榆中转移登记限制修改
      const dxLimit = this.selectedOrganizationsCode.startsWith('6211'); //定义预抵押之后的不动产还可以走转移登记流程
      this.arrData.map((item) => {
        this.$set(item, 'checked', false);
        //1、区分有单元号的去和无单元号的楼（是否有单元号）;拿到所有单元号
        if (item.dyh === '0' || !item.dyh) {
          this.noUnitHouseData.push(item);
        } else {
          this.unitHouseData.push(item);
        }
        if (this.checkBdcqzh !== '' && item.bdcqzh.includes(this.checkBdcqzh)) {
          this.$set(item, 'border', true); //不动产权
        }
        let extStatus = item.extStatus;
        let djlx = item.djlx ? ['0101', '0103', '0105', '0106', '0109'].includes(item.djlx) : false;
        if (this.firstFlow.thirdTypeData.netFlowdefCode === 'H999002') {
          // 转移+抵押
          //转本位 非白户sfbdc 1 sfyg 1 sfygdy 1 djdl 100或700 ------ 补录业务没有djdl和qszt
          // if (this.firstFlow.fourTypeData.name === '现房按揭') {
          const isXFZJ = extStatus && parseInt(extStatus.sfbdc) === 1;
          if (isXFZJ) {
            this.$set(item, 'isDisabled', false);
          } else {
            this.$set(item, 'isDisabled', true);
          }
        } else if (this.firstFlow.thirdTypeData.netFlowdefCode === 'H999001') {
          //   djlx &&
          //双预告
          const isSfsyg =
            !extStatus ||
            (parseInt(extStatus.qsblzt) === 0 &&
              !item.djlx &&
              extStatus.sfbdc === 0 &&
              parseInt(extStatus.sfyg) !== 1 &&
              parseInt(extStatus.sfygdy) !== 1);
          if (isSfsyg) {
            this.$set(item, 'isDisabled', false);
          } else {
            this.$set(item, 'isDisabled', true);
          }
        } else if (this.firstFlow.thirdTypeData.netFlowdefCode === 'H100203') {
          //组合贷'H100203'
          const isSfsyg =
            extStatus &&
            ((parseInt(extStatus.qsblzt) === 0 && !item.djlx) ||
              (parseInt(extStatus.sfbdc) !== 1 && djlx && parseInt(extStatus.qsblzt) !== 1)) &&
            parseInt(extStatus.sfyg) !== 0 &&
            parseInt(extStatus.sfygdy) !== 1;
          if (isSfsyg) {
            this.$set(item, 'isDisabled', false);
          } else {
            this.$set(item, 'isDisabled', true);
          }
        } else if (this.firstFlow.thirdTypeData.netFlowdefCode === 'N200104') {
          // 兰州榆中--抵押登记已经预告之后不允许在走抵押登记
          // const isSPFMM =
          //   extStatus && parseInt(extStatus.sfbdc) === 1 && parseInt(extStatus.sfyg) !== 1 && specialLimit && djlx;
          let isSPFMM = false;

          isSPFMM =
            extStatus &&
            parseInt(extStatus.sfbdc) === 1 &&
            parseInt(extStatus.qsblzt) !== 1 &&
            (item.djlx !== '0102' || this.firstFlow.fourTypeData.name === '集资建房');

          if (isSPFMM) {
            this.$set(item, 'isDisabled', false);
          } else {
            this.$set(item, 'isDisabled', true);
          }
        } else if (this.firstFlow.thirdTypeData.netFlowdefCode === 'N100104') {
          let isSPFMM = false;
          isSPFMM = !extStatus || (parseInt(extStatus.sfbdc) !== 1 && parseInt(extStatus.qsblzt) !== 1);
          if (isSPFMM) {
            this.$set(item, 'isDisabled', false);
          } else {
            this.$set(item, 'isDisabled', true);
          }
        } else if (this.firstFlow.thirdTypeData.netFlowdefCode === 'N10020301') {
          let isSPFMM = false;
          isSPFMM =
            !extStatus ||
            (parseInt(extStatus.sfbdc) !== 1 && parseInt(extStatus.qsblzt) !== 1 && parseInt(extStatus.sfygdy) === 1);
          if (isSPFMM) {
            this.$set(item, 'isDisabled', false);
          } else {
            this.$set(item, 'isDisabled', true);
          }
        } else if (this.firstFlow.thirdTypeData.netFlowdefCode === 'N100203') {
          let isSPFMM = false;
          isSPFMM =
            !extStatus ||
            (parseInt(extStatus.sfbdc) !== 1 &&
              parseInt(extStatus.qsblzt) !== 1 &&
              parseInt(extStatus.sfygdy) !== 1 &&
              parseInt(extStatus.sfyg) !== 0);
          if (isSPFMM) {
            this.$set(item, 'isDisabled', false);
          } else {
            this.$set(item, 'isDisabled', true);
          }
        } else {
          //预告流程 前提条件：空户 首登 未走预告预抵押 非在办
          const isSD =
            !extStatus ||
            (parseInt(extStatus.qsblzt) === 0 &&
              !item.djlx &&
              extStatus.sfbdc === 0 &&
              parseInt(extStatus.sfyg) !== 1 &&
              parseInt(extStatus.sfygdy) !== 1);
          if (isSD) {
            this.$set(item, 'isDisabled', false);
          } else {
            this.$set(item, 'isDisabled', true);
          }
          // if (parseInt(extStatus.qsblzt) === 0) {
          //   this.$set(item, 'isDisabled', true);
          // }0
        }
        if (item.status === 'IN_PROCESS' || parseInt(item.isBdcdybaType) === 1) {
          this.$set(item, 'isDisabled', true); //在办 || 不动产单元已备案不可走转移或抵押
        }
        //sfbdc 是否存在产权，1是
        if (extStatus && parseInt(extStatus.sfbdc)) {
          this.$set(item, 'color', '#34CA85'); //不动产权
        }
        if (extStatus && parseInt(extStatus.sfdya) === 1 && this.firstFlow.thirdTypeData.netFlowdefCode !== 'N100104') {
          //字符'0'可以进，需转nub
          this.$set(item, 'color', '#3EA1F4'); //抵押
          this.$set(item, 'isDisabled', true);
        }
        if (extStatus && parseInt(extStatus.sfcf) === 1) {
          this.$set(item, 'color', '#FF6666'); //查封
          if (this.firstFlow.thirdTypeData.netFlowdefCode !== 'N100104') this.$set(item, 'isDisabled', true);
        } else if (
          extStatus &&
          parseInt(extStatus.sfcf) === 2 &&
          this.firstFlow.thirdTypeData.netFlowdefCode !== 'N100104'
        ) {
          this.$set(item, 'color', '#FFA459'); //预查封
          this.$set(item, 'isDisabled', true);
        }
        if (extStatus && parseInt(extStatus.sfyg)) {
          this.$set(item, 'color', '#A2A2DA'); //预告
        }
        if (extStatus && parseInt(extStatus.sfygdy)) {
          this.$set(item, 'color', '#f959ff'); //预告抵押
        }
        if (extStatus && parseInt(extStatus.sfyy)) {
          this.$set(item, 'color', '#FFCC33'); //异议
          this.$set(item, 'isDisabled', true);
        }
        if (extStatus && parseInt(extStatus.sfdyi)) {
          this.$set(item, 'color', '#00C1CE'); //地役
          this.$set(item, 'isDisabled', true);
        }
      });
      this.disposeUnitHouseData();
      this.disposeNoUnitHouseData();
    },
    //组织有单元号的楼数据结构
    disposeUnitHouseData() {
      const zhData = []; //暂时存单元号集合
      const temSjcData = []; //暂存层数集合
      this.unitHouseData.map((item) => {
        zhData.push(item.dyh);
        temSjcData.push(item.sjc); //sjc字符串提取数字
        this.$set(item, 'sjc', item.sjc); //sjc统一转为数字
      });
      this.finalZhData = [...new Set(zhData)].sort((v1, v2) => {
        return parseInt(v1) - parseInt(v2);
      });
      //去除多余的’0‘ //异常：vue.runtime.esm.js:619 [Vue warn]: Invalid value for option "methods": expected an Object, but got Function.
      if (this.finalZhData[0] === '0') {
        this.finalZhData.shift();
      }
      // console.log(this.finalZhData, 'finalZhData-单元号的集合'); //
      // console.log(this.noUnitHouseData,'非住宅区数据')
      // console.log(this.unitHouseData, '住宅区数据');
      //2 拿到每一幢跨列数（即每个单元的每一户最多有多少户）--th
      //2.1、按幢将数据分成n份
      const Arr = []; //按幢分类户信息--[[],[],……]结构
      this.finalZhData.forEach((item, index) => {
        Arr[index] = this.unitHouseData.filter((res) => {
          return res.dyh === this.finalZhData[index];
        });
      });
      // console.log(Arr, '按幢分类户信息');
      //2.2、先拿到所有的实际层集合-思路同拿到单元号集合
      this.sjcArr = [...new Set(temSjcData)]
        .sort((v1, v2) => {
          return parseInt(v1) - parseInt(v2);
        })
        .reverse();
      // console.log(this.sjcArr, '实际的层集合');
      //2.3、算出每个单元，每一层有多少户
      Arr.map((arr, index) => {
        for (let i = 0; i < this.sjcArr.length; i++) {
          arr.map((res) => {
            if (res.sjc === this.sjcArr[i]) {
              this.maxHu++;
            }
          });
          this.maxHuArr.push({ dyh: index + 1, maxHu: this.maxHu, id: `${this.sjcArr.length - i}层` });
          this.maxHu = 0;
        }
        // console.log(this.maxHuArr, '算出每个单元，每一层最多有多少户');
        //2.4、算出每个单元，每一层最多有多少户
        const temporaryArr = [];
        this.maxHuArr.forEach((item) => {
          temporaryArr.push(item.maxHu);
        });
        let zhMaxHu; //该单元中，每层户数的最大值
        zhMaxHu = [...new Set(temporaryArr)].sort((v1, v2) => {
          return parseInt(v1) - parseInt(v2);
        });

        // console.log(zhMaxHu, '该单元中，每层户数的最大值');
        this.colspanArr.push({ dyh: index + 1, maxHu: zhMaxHu[zhMaxHu.length - 1] });
        this.maxHuArr = [];
        // console.log(this.colspanArr, 'colspanArr-最终th数据，每个单元的跨列数');
      });
      // console.log(this.maxHuArr, '算出每个单元，每一层最多有多少户');

      // 3、处理tbody数据--td--数据结构设计sumRowData[{sjc:xx,data:[{户1},{户2},……]},{},……]
      for (let i = 0; i < this.sjcArr.length; i++) {
        // 3.1、按实际层分类数据
        let sjcData = this.unitHouseData.filter((res) => {
          return res.sjc === this.sjcArr[i]; //1
        });
        // console.log(sjcData, `第${i + 1}层数据`);
        // 3.2、每一层数据排序处理
        this.finalZhData.forEach((dyh, index) => {
          //temporary: 拿到第n层，第m单元的所有户
          this.temporary = sjcData.filter((item) => {
            return dyh === item.dyh;
          });
          // console.log(this.temporary, `this.temporary-${this.sjcArr.length - i}层${index + 1}幢的该户数据`);
          this.hsbwSort(this.temporary, this.sjcArr.length - i);
          //比对temporary的shbw字段数据，测试户排序是否成功
          // if(this.sjcArr.length - i === 5) {
          //    console.log(this.temporary,'temporary555')
          // }
          // 空户处理（有空格）--最终组织的sumRowData所有空{}，都是空户，加个判断处理
          // console.log(parseInt(this.colspanArr[index].maxHu.toString()), 'parseInt(this.colspanArr)'); //
          // console.log(parseInt(this.temporary.length.toString()), 'parseInt(this.temporary.length.toString()'); //
          let balance = Math.abs(parseInt(this.colspanArr[index].maxHu) - parseInt(this.temporary.length.toString()));
          //测试空户数量
          // console.log(balance, ` balance,${i + 1}层${index + 1}单元有${balance}空户`);
          if (balance) {
            //如何向数组插入元素，个数是动态的-如果balace是2，则向数组temporary插入两个‘’/或者空对象
            //问-eg-第1层1单元有103是有的，其它101，102，104是空的，怎么让103在表格展示的时候放到对应的位置？
            //有没有办法知道具体的空户室号是多少？
            //思路一、不考虑空户的位置
            this.disposeEmptyHouse(balance);
            this.temporary.map((rowData) => {
              this.rowData.data.push(rowData);
            });
            return;
          }
          this.temporary.map((rowData) => {
            this.rowData.data.push(rowData);
          });
        });
        // 每层数据（按楼层从小到大，按单元号（单元号）从小到大，户号从小到大）
        this.rowData.sjc = this.sjcArr[i];
        this.sumRowData.push(this.rowData); //组织后的数据结构，最终要的tbody数据集合
        //一定要置空，否则有引用数据类型常见错误
        this.rowData = {
          sjc: '',
          data: [],
        };
        // console.log(this.sumRowData, '组织后的数据结构，最终要的tbody数据集合');
      }
      // console.log(this.rowData, 'rowData-每一层数据');
    },
    //组织无单元号的楼数据结构
    disposeNoUnitHouseData() {
      //1、th跨列数
      //拿到非住宅区实际层集合（从小到大）
      const temSjcData = []; //暂存层数集合
      this.noUnitHouseData.map((item) => {
        temSjcData.push(parseInt(item.sjc));
        this.$set(item, 'sjc', parseInt(item.sjc));
      });
      this.nosjcArr = [...new Set(temSjcData)]
        .sort((v1, v2) => {
          return parseInt(v1) - parseInt(v2);
        })
        .reverse();
      // console.log(this.nosjcArr, '非住宅区-实际的层集合');
      const colSpanArr = []; //每一层的户数集合
      const sjcData = []; //按层分类的非住宅数据
      this.nosjcArr.forEach((item, k) => {
        sjcData[k] = this.noUnitHouseData.filter((res) => {
          return res.sjc === item;
        });
        colSpanArr.push(sjcData[k].length);
        // console.log(sjcData[k], `第${k + 1}层数据`);
      });
      // console.log(colSpanArr,'arr-每一层的户数')
      //非住宅区-th的最大跨列数
      this.noMaxColspan = colSpanArr.sort((v1, v2) => {
        return parseInt(v1) - parseInt(v2);
      })[colSpanArr.length - 1];
      // console.log(this.noMaxColspan,'非住宅区-th的最大跨列数')
      //2、处理tbody数据-noSumRowData[{sjc:xxx,data:[]},……]
      //按实际层分类数据-sjcData
      // console.log(sjcData,'按实际层分类数据')
      for (let i = 0; i < this.nosjcArr.length; i++) {
        this.noRowData.sjc = this.nosjcArr[i];
        this.noRowData.data = [...sjcData[i]];
        // console.log(this.noRowData.data, `noRowData.data-第${i+1}层`);
        //对每一层按shbw字段，eg1-地下商铺1室
        this.hsbwSort(this.noRowData.data);
        this.noSumRowData.push(this.noRowData);
        this.noRowData = {
          sjc: '',
          data: [], //该层所有户的数据
        };
      }
      // console.log(this.noSumRowData, '组织后的/数据结构，最终要的tbody数据集合');
    },
    //处理空户--暂时是把空户都放在前面--以后优化
    disposeEmptyHouse(balance) {
      if (balance) {
        // this.temporary.splice(0, 0, {});
        this.temporary.push({});
        balance--;
        this.disposeEmptyHouse(balance);
      }
    },
    //勾选图例
    checkChangeMj(value) {
      // console.log(value, '勾选图例');
      this.showJZMJ = false;
      this.showYCJZMJ = false;
      this.showTNMJ = false;
      this.showFWYT = false;
      if (value.includes('实测建筑面积')) {
        this.showJZMJ = true;
      }
      if (value.includes('预测建筑面积')) {
        this.showYCJZMJ = true;
      }
      if (value.includes('套内面积')) {
        this.showTNMJ = true;
      }
      if (value.includes('房屋用途')) {
        this.showFWYT = true;
      }
    },
    //展示户详情信息
    viewinfofun(houseInfo) {
      houseInfo.xmmc = this.xmmc ? this.xmmc : houseInfo.xmmc;
      this.dialogUnitInfo = true;
      this.$nextTick(() => {
        this.houseDetailsInfo = JSON.parse(JSON.stringify(houseInfo));
      });
    },
    //关闭户详情
    dialogBeforeClose() {
      this.dialogUnitInfo = false;
    },
    /**
     * 楼盘表户选择
     * @param item 该户所有信息
     * @param mark 该户唯一标识
     */
    // eslint-disable-next-line no-unused-vars
    checkHouse(item, mark) {
      // eslint-disable-next-line no-console
      // console.log(item, '勾选单户详情信息', mark, '该户标识');
    },
    /**
     * 楼盘表选择-按层全选/按层取消全选/户全选/户全不选
     * @param item 被选中的该层数据（只有按层全选，层取消全选有数据）/全选或全不选标识
     * @param mark 唯一标识---按层全选/取消全选/户全选/户全不选
     */
    checkHang(item, mark) {
      //住宅区层全选
      if (mark === '住宅区') {
        // console.log(item, '按层全选-楼盘表选择');
        this.sumRowData.forEach((rowItem, i) => {
          if (rowItem.sjc === item.sjc) {
            rowItem.data.map((res, j) => {
              //筛选空户
              if (JSON.stringify(this.sumRowData[i].data[j]) !== '{}') {
                //不能选择：查封、抵押、异议、预查封、产权
                if (!this.sumRowData[i].data[j].isDisabled) {
                  //isDisabled属性不存在即表示可以选择
                  //解决vue数据更新之后,dom没有更新问题
                  this.$set(this.sumRowData[i].data[j], 'checked', item.checked);
                }
              }
            });
          }
        });
        // console.log(this.sumRowData, '住宅区-层选择后的数据');
      }
      //非住宅区层全选
      if (mark === '非住宅区') {
        // console.log(item, '按层全选-楼盘表选择');
        this.noSumRowData.forEach((rowItem, i) => {
          if (rowItem.sjc === item.sjc) {
            rowItem.data.map((res, j) => {
              //筛选空户
              if (JSON.stringify(this.noSumRowData[i].data[j]) !== '{}') {
                //不能选择：查封、抵押、异议、预查封、产权
                if (!this.noSumRowData[i].data[j].isDisabled) {
                  //解决vue数据更新之后,dom没有更新问题
                  this.$set(this.noSumRowData[i].data[j], 'checked', item.checked);
                }
              }
            });
          }
        });
        // console.log(this.noSumRowData, '非住宅区-层选择后的数据');
      }
      //全选
      if (mark === '全选' && item) {
        //注意：利用arrData是引用数据类型,修改值具有自上而下性，会自动改变所有取arrData值的修改
        this.arrData.map((item) => {
          //不能选择：查封、抵押、异议、预查封、产权
          if (!item.isDisabled) {
            this.$set(item, 'checked', true);
          }
        });
        this.sumRowData.map((item, index) => {
          this.$set(this.sumRowData[index], 'checked', true);
          // this.checkHang(null,'住宅区')
        });
        this.noSumRowData.map((item, index) => {
          this.$set(this.noSumRowData[index], 'checked', true);
          // this.checkHang(null,'非住宅区')
        });
        // console.log(this.sumRowData, 'sumRowData-全选');
        // console.log(this.noSumRowData, 'noSumRowData-全选');
      }
      //全不选
      if (mark === '全不选' && !item) {
        //不能选择：查封、抵押、异议、预查封、产权
        this.arrData.map((item) => {
          if (!item.isDisabled) {
            this.$set(item, 'checked', false);
          }
        });
        this.sumRowData.map((item, index) => {
          if (item.hasOwnProperty('checked')) {
            if (item.checked) {
              // console.log('取消全选-空户的处理');
              this.$set(this.sumRowData[index], 'checked', false);
            }
          }
        });
        this.noSumRowData.map((item, index) => {
          if (item.hasOwnProperty('checked')) {
            if (item.checked) {
              this.$set(this.noSumRowData[index], 'checked', false);
            }
          }
        });
        // console.log(this.sumRowData, 'sumRowData-全不选');
        // console.log(this.noSumRowData, 'noSumRowData-不选');
      }
    },
    //最终勾选的所有户数据/写进接口
    selectedHouse() {
      this.selectedHouseSum = []; //重置
      this.sumRowData.filter((item) => {
        item.data.map((hosue) => {
          if (hosue.hasOwnProperty('checked') && hosue.checked) {
            this.selectedHouseSum.push(hosue);
          }
        });
      });
      this.noSumRowData.filter((item) => {
        item.data.map((hosue) => {
          if (hosue.hasOwnProperty('checked') && hosue.checked) {
            this.selectedHouseSum.push(hosue);
          }
        });
      });
      // eslint-disable-next-line no-console
      this.selectedHouseSum = this.getqlxxExMhs(this.selectedHouseSum);
      // console.log(this.selectedHouseSum, '最终勾选的所有户数据');
      this.$emit('selectedHouse', this.selectedHouseSum);
    },
    /*
     * 获取选中户的权利信息
     * @param huArray 选中的户信息
     * @param subFlowname 三级流程名称
     */
    getqlxxExMhs(huArray) {
      (huArray || []).forEach((item) => {
        item.fwcqmj = item.jzmj;
      });
      return huArray;
    },
    //hsbw排序(包括住宅区/非住宅区)
    hsbwSort(temporary) {
      // 正则直接匹配所有数字；对第n层，第m单元的所有户按从--“户号”--从小到大依次排序；接口返回数据：eg-602室/602/602号/商铺602
      temporary.sort(function (v1, v2) {
        let arrayMatch1 = v1.shbw.match(/\d+/g);
        let arrayMatch2 = v2.shbw.match(/\d+/g);
        if (!arrayMatch1) {
          arrayMatch1 = [0];
        }
        if (!arrayMatch2) {
          arrayMatch2 = [0];
        }
        let nNum1 = Number(arrayMatch1[arrayMatch1.length - 1]);
        let nNum2 = Number(arrayMatch2[arrayMatch2.length - 1]);
        return nNum1 - nNum2;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/pages/iebdc/styles/public';
@import 'src/pages/iebdc/styles/common-variables';
.lpb {
  .house-box .house-legend .checkbox-label {
    cursor: auto;
  }
  // 新增样式 mj
  .lh-26 {
    line-height: 26px;
  }
}
.checkbox-label {
  // 新增 mj start
  /deep/ .el-checkbox-group {
    /deep/ .el-checkbox.is-bordered {
      width: 120px;
      padding: 5px 0 !important;
      text-align: center;
      line-height: 12px;
    }
    /deep/ .el-checkbox__label {
      padding: 0;
      line-height: 14px;
    }
  }
  // 新增 mj end
  .select-show {
    margin-left: 10px !important;
  }
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  //background-color: #409eff;
  border-color: #409eff;
}
//图标
.house-box {
  margin-bottom: $spacing-large;
  height: 80%;
  flex: 1;
  display: flex;
  flex-direction: column;
  .house-lpb {
    margin-bottom: $spacing-large;
    height: 50%;
    flex: 1;
    /deep/ .el-scrollbar__wrap {
      overflow: scroll;
      height: 400px !important;
    }
    .el-scrollbar__bar {
      opacity: 1;
    }
  }
  .house-xmxx {
    padding: 10px;
    background: #eeffff;
    border-radius: 8px 8px 0 0;
    .left {
      font-size: 18px;
      font-weight: 700;
      margin-left: $spacing-large;
    }
    .right {
      float: right;
      margin-right: 20px;
    }
  }
  .house-legend {
    margin-top: 10px;
    // text-align: center; mj 修改处
    text-align: left;
    .house-legend-icon {
      font-size: 16px;
      font-weight: bold;
    }
    .legend-item {
      font-size: 14px;
      width: 80px;
      display: inline-block;
      text-align: center;
      margin: 0 10px;
      border-radius: 0.25em;
      padding: 5px 0;
      color: white;
      height: auto;
    }
    .checkbox-label {
      margin-top: 15px;
      margin-bottom: 15px;
      font-weight: normal;
      vertical-align: middle;
      cursor: pointer;
      display: flex;
      // justify-content: center; mj 修改处
      .el-checkbox__inner {
        border: 1px solid #ccc;
      }
      .el-checkbox-group {
        margin-right: 40px;
      }
      .is-bordered {
        margin-right: 15px;
      }
    }
  }
}
.hignLightStyle {
  color: #353535;
  font-weight: bold;
}
.borderLightStyle {
  border: 3px solid #00e905;
}
//楼盘表格
.house-table {
  margin: 10px 0;
  border: 1px solid #ccc !important;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  /deep/ .el-checkbox-style {
    .el-checkbox__inner {
      border: 1px solid #ccc;
      background-color: #afadb8;
    }
  }
  .gudinglie {
    width: 100px;
    min-width: 100px;
    padding: 8px 8px;
    vertical-align: middle;
    border-spacing: 0;
    border: 1px solid #ccc !important;
    text-align: center;
    background: white;
  }
  .house-th {
    background: white;
    border-bottom: none;
    font-weight: 700;
    color: #353535;
  }
  .color-white {
    color: white;
  }
  .house-td {
    width: auto;
    min-width: 170px;
    background: white;
    .lpb-content {
      padding: $spacing-base 6px;
      width: 100%;
      .dis-label {
        position: relative;
        padding-right: 20px;
        .icon {
          right: 0;
          top: 0;
          position: absolute;
          cursor: pointer;
          display: none;
        }
      }
      .dis-label:hover {
        .icon {
          display: block;
        }
        .icon:hover {
          color: #ccc;
        }
      }
    }
    .lpb-content:hover {
      color: black;
      font-weight: 700;
    }
  }
}
.lpb-content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .header-info {
    position: absolute;
    top: 0;
    left: 0;
    .key {
      color: #26762a;
      display: inline-block;
      line-height: 1.3rem;
      margin-right: 10px;
    }
    .value {
      margin-right: 20px;
      color: black;
    }
  }
  .header-btn {
    text-align: center;
    margin-top: 10px;
    .el-button {
      min-width: 90px;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  .dis-label-lcbs .lcbs span {
    // color: #ffffff;
    margin: 0 5px;
  }
}
::v-deep .el-scrollbar__bar.is-vertical {
  border-radius: 0 !important;
  width: 14px !important;
}
::v-deep .el-scrollbar__bar.is-horizontal {
  border-radius: 0 !important;
  height: 14px !important;
}
</style>
