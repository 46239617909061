<template>
  <div class="show-house-table box-shadow-default">
    <el-form ref="form" :label-position="labelPosition" :model="params" label-width="110px" size="small">
      <el-row :gutter="40">
        <el-col :span="22">
          <el-form-item label="不动产坐落" prop="zlData.zlArea" :rules="[validate.required]">
            <zl-directives v-model="params.zlData" :zlConfig="zlConfig"></zl-directives>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="项目名称"
            v-if="userInfoData.userCategory === 'FINANCIAL' || userInfoData.userCategory === 'KFS'"
            prop="xmmc"
          >
            <el-select v-model="zParams.zddm" filterable placeholder="请选择项目名称">
              <el-option v-for="(item, i) in zdOptions" :label="item.xmmc" :value="item.zddm" :key="i"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="不动产单元号">
            <el-input v-model="zParams.bdcdyh" placeholder="不动产单元号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="坐落">
            <el-input v-model="zParams.zl" placeholder="请输入坐落"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- H100203:预告抵押权首次登记+预告抵押权首次登记（组合贷） N100203：预售商品房抵押权预告登记-->
      <el-row v-if="['H100203', 'N100203'].includes(firstFlow.thirdTypeData.netFlowdefCode)">
        <el-form-item label="不动产登记证明号" label-width="140px">
          <el-input v-model="zParams.bdcdjzmh" placeholder="不动产登记证明号"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :span="4" :offset="20">
          <el-tooltip class="item" effect="dark" content="注意输入查询条件" placement="top">
            <el-button type="primary" @click="getZlist" :loading="isLoading">查询</el-button>
          </el-tooltip>
          <!-- <el-button type="primary" @click="toMessage">信息反馈</el-button> -->
        </el-col>
      </el-row>
      <el-table :data="Zlist" border stripe>
        <el-table-column prop="zt" label="状态" align="center">
          <template slot-scope="scope">
            <span class="legend-item" style="background-color: #ff6666" v-if="scope.row.sfxf === '是'">现楼盘</span>
            <span class="legend-item" style="background-color: #3ea1f4" v-else-if="scope.row.sfxf === '否'"
              >期楼盘</span
            >
            <span></span>
          </template>
        </el-table-column>
        <el-table-column prop="bdcdyh" label="不动产单元号"> </el-table-column>
        <el-table-column prop="zl" label="坐落"> </el-table-column>
        <el-table-column prop="jzwmc" label="建筑物名称"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="getHlist(scope.row)" type="text" size="small" :loading="loading"> 查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :span="24" class="pagination-col">
          <el-pagination
            @current-change="currentPageClickHandle"
            :current-page.sync="zParams.nCurrent"
            :page-size="zParams.nSize"
            layout="prev, pager, next, total, jumper"
            :total="zParams.total"
            :pager-count="7"
          >
          </el-pagination>
        </el-col>
      </el-row>
    </el-form>
    <Lpb
      ref="lpb"
      :legend-data="legendData"
      :details-checkbox="detailsCheckbox"
      :arrData="arrData"
      :showlpb="showlpb"
      :containHtxx="containHtxx"
      @nextBtnState="nextBtnState"
      @selectedHouse="getTdBdcdyhCfDy"
    />
    <div class="text-center main-footer">
      <el-button type="primary" @click="$router.back()">上一步</el-button>
      <el-button type="primary" @click="next" :disabled="isShow">下一步</el-button>
    </div>
    <el-dialog :visible.sync="pcbhVisible" title="请选择批次号">
      <el-form :model="pchbForm" ref="form" :rules="rules" label-width="80px" :inline="false" size="normal">
        <el-row>
          <el-col :span="19">
            <el-form-item label="批次编号">
              <el-select v-model="pchbForm.pcbh" placeholder="请选择批次编号" style="width: 100%" size="small">
                <el-option v-for="item in pcbhList" :label="item.pcbh" :value="item.pcbh" :key="item.pcbh"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3" :offset="1"> <el-button type="primary" @click="createPcbh">新增批次号</el-button> </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" :loading="submitLoading">确认</el-button>
          <el-button @click="pcbhVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Lpb from './lpb/Lpb';
import validate from '@/utils/validate';
import getlpbInfo from '@/pages/iebdc/api/apply/applicant-lpb';
import applicantSaveService from '@/pages/iebdc/api/apply/applicant-save';
import applicantRightService from '@/pages/iebdc/api/apply/applicant-right';
import applicantOperatorService from '@/pages/iebdc/api/apply/applicant-operator';
import { localDataUser } from '@/pages/iebdc/utils/local-data';
import { localDataDic } from '@iebdc/utils/local-data';
import { mapState, mapGetters } from 'vuex';
import { sqbConfig } from '@public/applyConfig';
import { userInfo } from '@/pages/iebdc/api/login';
import utils from '@iebdc/utils/utils';
import { sqrCategoryBg } from '@public/sqrCategoryBg.js';
import { getCookie } from '@iebdc/utils/cookies';
import { sameSqr } from '@public/applyConfig/sameSqr';
const dicObj = localDataDic.get() || {};
export default {
  name: 'showHouseTable',
  components: {
    Lpb,
  },
  data() {
    return {
      isLoading: false,
      sfxf: '', //当前选择楼盘是否现房
      pcbhList: [],
      pchbForm: {},
      submitLoading: false,
      pcbhVisible: false,
      userInfoData: localDataUser.get(),
      loading: false,
      //图例
      legendData: [
        {
          name: '查封',
          color: '#FF6666',
          key: 'SFCF',
        },
        {
          name: '预查封',
          color: '#FFA459',
          key: 'SFYCF',
        },
        {
          name: '异议',
          color: '#FFCC33',
          key: 'SFYY',
        },
        {
          name: '抵押',
          color: '#3EA1F4',
          key: 'SFDYA',
        },
        {
          name: '地役权',
          color: '#00C1CE',
          key: 'SFDYI',
        },
        {
          name: '预告',
          color: '#A2A2DA',
          key: 'SFYG1SFYGDY',
        },
        {
          name: '预抵押',
          color: '#f959ff',
          key: 'SFYGDY',
        },
        {
          name: '所有权',
          color: '#34CA85',
          key: 'SFBDC',
        },
      ],
      //详情-换个名字
      detailsCheckbox: {
        name: '',
        value: [],
        list: [
          { name: '实测建筑面积', val: '实测建筑面积' },
          { name: '预测建筑面积', val: '预测建筑面积' },
          { name: '套内面积', val: '套内面积' },
          { name: '房屋用途', val: '房屋用途' },
        ],
      },
      arrData: [], //所有楼盘表数据
      selectedHouseSum: [],
      showlpb: false,
      params: {
        //区域代码
        zlData: {
          zlProvince: '',
          zlCity: '',
          zlArea: '',
        },
        xmmc: '', //项目名称
        jzwmc: '', //幢号
        zddm: '', //宗地代码
        zrzh: '', //自然幢号
        lcdm: '', //流程代码
        lcxlmc: '', //流程小类名称
        selectedZddm: '', //选择的宗地代码
        htbh: '',
      },
      zxxDetails: {},
      Zlist: [],
      zParams: {
        qydm: getCookie('selectedAreaCode'),
        total: 0,
        nCurrent: 0,
        nSize: 10,
        zddm: '',
      },
      containHtxx: [],
      zdOptions: [], //宗地列表
      selectedZl: '', // 选中宗地代码对应坐落
      // selectedZddm: '', //选中的宗地代码
      zlConfig: {
        zlKey: 'code',
        rules: [],
        hideZl: true,
        disabled: true,
      },
      validate: validate,
      labelPosition: 'right',
      isShow: true, //是否显示下一步
      isShowlc: false, //是否显示当前查询条件
      jzwmcOptionsAll: [], //当前项目名称的所有幢号数组
      jzwmcOptions: [], //当前项目名称的前十条幢号数组
      ygCode: ['N100201', 'N10020301', 'H999001'], //预告流程code
      zyCode: ['H999002', 'N200104', 'YCN200104'], //转移流程code
    };
  },
  computed: {
    // 选择的流程类型信息
    ...mapState('wysq-data', ['firstFlow']),
    ...mapState('wysq-step', ['nextItem']),
    ...mapGetters(['selectedOrganizationsCode']),
    // 选择的机构信息
    getSelectedOrgInfo() {
      return this.$store.getters['dashboard/getSelectedOrgInfo'];
    },
    // isshowHtbh(){

    // }
  },
  watch: {
    'params.xmmc': function (val) {
      this.params.jzwmc = '';
      this.jzwmcOptions = [];
      this.zdOptions.map((item, index) => {
        if (index === val) {
          this.$nextTick(() => {
            if (this.userInfoData.userCategory === 'KFS' && !this.isShowlc) {
              this.params.zddm = item.zddm.split(',');
              this.params.selectedZddm = '';
            }
            //坐落
            this.selectedZl = item.zl;
            if (item.jzwmc) {
              // if (this.isJsonObj(item.jzwmc)) {
              //   this.jzwmcOptionsAll = $.extend(true, [], JSON.parse(item.jzwmc));
              // } else {
              //   this.jzwmcOptionsAll = $.extend(true, [], [item.jzwmc]);
              // }
              // this.jzwmcOptionsAll.map((item) => {
              //   if (item) {
              //     this.jzwmcOptions.push(item);
              //   }
              // });
              if (item.jzwArr) {
                // this.jzwmcOptionsAll = item.jzwArr;
                item.jzwArr.map((item) => {
                  if (item) {
                    this.jzwmcOptions.push(item.jzwmc);
                  }
                });
              }
            }
          });
        }
      });
    },
    'params.zlData.zlArea': function (val) {
      //选择区域时重置已选择的数据
      this.params.xmmc = '';
      this.params.selectedZddm = '';
      this.params.zddm = this.userInfoData.userCategory === 'KFS' ? [] : '';
      if (!this.isShowlc) {
        let zdxxesArr = [];
        zdxxesArr = (this.userInfoData.zdxxes || []).filter(({ zddm }) => zddm.substring(0, 6) === val);
        this.zdOptions = [...zdxxesArr];
      }
    },
  },
  methods: {
    //根据合同号查询网签信息
    async queryWqxx(fwlb) {
      const params = {
        // htbh: this.params.htbh,
        htbh: this.params.htbh,
        type: '1',
        fwlb: fwlb,
        areacode: this.params.zlData.zlArea || '',
      };
      const { success, data } = await getlpbInfo.queryWqxx(params);
      if (success) {
        this.containHtxx = data;
      }
    },
    currentPageClickHandle(val) {
      this.zParams.nCurrent = val;
      this.getZlist();
    },
    searchHouseTable() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // if (this.userInfoData.userCategory === 'KFS' && this.params.selectedZddm === '') {
          //   this.$message.warning('请选择宗地代码');
          //   return false;
          // }
          this.loading = true;
          let params = {
            qydm: this.params.zlData.zlArea,
            xmmc: this.zdOptions[this.params.xmmc].xmmc,
            jzwmc: this.jzwmcOptions[this.params.jzwmc],
            zddm: this.params.selectedZddm ? this.params.selectedZddm : this.params.zddm,
            zrzh: this.params.zrzh,
            lcdm: this.params.lcdm,
            lcxlmc: this.params.lcxlmc,
          };
          const a = [];
          a.push(params);
          let htxx = '';
          // 提取楼盘表
          this.showlpb = true;
          getlpbInfo
            .getlpbInfo({ params: a })
            .then((res) => {
              if (res.success && res.data) {
                if (this.params.htbh !== '') {
                  if (this.ygCode.indexOf(this.firstFlow.thirdTypeData.netFlowdefCode) >= 0) {
                    htxx = this.queryWqxx('y');
                  } else if (this.zyCode.indexOf(this.firstFlow.thirdTypeData.netFlowdefCode) >= 0) {
                    htxx = this.queryWqxx('z');
                  }
                }
                // console.log(htxx);
                if (res.data[0] && res.data[0].fwxx && res.data[0].fwxx.length < 1) {
                  this.$message.warning('楼盘表无数据！！！');
                  this.arrData = [];
                  this.$nextTick(() => {
                    this.isShow = false;
                    this.$refs.lpb.init(1, params.xmmc);
                  });
                } else {
                  let newData = [...res.data[0].fwxx];
                  if (this.params.bdcdyh) {
                    newData.forEach((item) => {
                      if (item.bdcdyh === this.params.bdcdyh) {
                        item.isChoice = true;
                      } else {
                        item.isChoice = false;
                      }
                    });
                  }
                  this.arrData = newData;
                  this.$nextTick(() => {
                    this.showlpb = true;
                    this.$refs.lpb.init(2, params.xmmc);
                  });
                }
              } else {
                this.arrData = [];
                this.$message.warning('楼盘表无数据！！！');
              }
              this.loading = false;
            })
            .catch(() => {
              this.arrData = [];
              this.$nextTick(() => {
                this.showlpb = true;
                this.$refs.lpb.init(1, params.xmmc);
              });
              this.loading = false;
              this.$message.warning('楼盘表无数据！！！');
            });
        } else {
          return false;
        }
      });
    },
    next() {
      // this.pcbhVisible = true;
      this.$refs.lpb.selectedHouse();
    },
    onSubmit() {
      this.submitLoading = true;
      setTimeout(() => {
        this.submitLoading = false;
        this.pcbhVisible = false;
      }, 10000);
      this.selectedHouse(this.selectedHouseSum);
      // this.$refs.lpb.selectedHouse();
    },
    getZlist() {
      this.isLoading = true;
      getlpbInfo
        .getZlist(this.zParams)
        .then((res) => {
          if (res.success) {
            this.Zlist = res.data.records;
            this.zParams.total = res.data.total;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    toMessage() {
      this.$router.push({
        name: 'message',
        params: {},
      });
    },

    saveBdcxxByhtxx(selectedHouseSum) {
      console.log(selectedHouseSum);
      let qlxxEx = {};
      let paramsHtxx = {
        htbh: this.params.htbh,
        type: '2',
        fwlb: '',
        areacode: this.params.zlData.zlArea || '',
      };
      const bdcdyhs = [selectedHouseSum[0].bdcdyh];
      if (this.ygCode.indexOf(this.firstFlow.thirdTypeData.netFlowdefCode) >= 0) {
        paramsHtxx.fwlb = 'y';
      } else if (this.zyCode.indexOf(this.firstFlow.thirdTypeData.netFlowdefCode) >= 0) {
        paramsHtxx.fwlb = 'z';
      }

      getlpbInfo.queryWqxx(paramsHtxx).then((resHtxx) => {
        if (resHtxx.success) {
          let htxx = resHtxx.data.data.htxx ? resHtxx.data.data.htxx[0] : {};
          qlxxEx.gfhtbh = htxx.HTBH;
          qlxxEx.zl = htxx.FWZL;
          qlxxEx.htje = Number(htxx.HTJE) / 10000;
          qlxxEx.scjzmj = htxx.JZMJ;

          selectedHouseSum.map((item) => {
            const updateHouse = {
              fwyt: item.ghyt,
              ghyt: item.ghyt,
              tdqsrq: item.tdqsrq,
              tdzzrq: item.tdzzrq,
              fwcqmj: typeof item.jzmj === 'number' ? item.jzmj.toString() : item.jzmj,
              jzmj: typeof item.jzmj === 'number' ? item.jzmj.toString() : item.jzmj,
              ycjzmj: typeof item.ycjzmj === 'number' ? item.ycjzmj.toString() : item.ycjzmj,
              tnjzmj: typeof item.tnjzmj === 'number' ? item.tnjzmj.toString() : item.tnjzmj,
              ftjzmj: typeof item.ftjzmj === 'number' ? item.ftjzmj.toString() : item.ftjzmj,
              fttdmj: typeof item.fttdmj === 'number' ? item.fttdmj.toString() : item.fttdmj,
              dytdmj: typeof item.dytdmj === 'number' ? item.dytdmj.toString() : item.dytdmj,
              fwjg: this.formatDic('房屋结构', item.fwjg),
              fwlx: this.formatDic('房屋类型', item.fwlx),
              fwxz: this.formatDic('房屋性质', item.fwxz),
              szc: item.sjc,
            };
            Object.assign(item, updateHouse);
          });
          if (selectedHouseSum.length) {
            let flowname = '';
            if (this.firstFlow.thirdTypeData.flowname) {
              flowname = this.firstFlow.thirdTypeData.flowname;
            } else {
              flowname = this.firstFlow.thirdTypeData.name;
            }
            //存勾选的所有户数据
            const params = {
              sfyddbl: 0, //是否移动端办理
              agentInfoId: this.userInfoData.dlrId || '',
              userId: localDataUser.get().hasOwnProperty('jbrData')
                ? localDataUser.get().jbrData.id
                : this.userInfoData.id, // 用户id
              sqrlx: this.userInfoData.userCategory, // 申请人类型
              bdclb: this.firstFlow.secondTypeData.bdclbEnum, // 不动产类别
              flowcode: this.firstFlow.thirdTypeData.flowCode,
              netFlowdefCode: this.firstFlow.thirdTypeData.netFlowdefCode,
              flowname: flowname,
              djdl: this.firstFlow.thirdTypeData.djdl,
              qllx: this.firstFlow.thirdTypeData.qllx,
              subFlowcode: this.firstFlow.fourTypeData.code,
              subFlowname: this.firstFlow.fourTypeData.name,
              taskCode: this.firstFlow.thirdTypeData.taskCode,
              taskHandleItem: this.firstFlow.thirdTypeData.taskHandleItem,
              pjlcmc: this.firstFlow.thirdTypeData.pjlcmc,
              qlxxExMhs: selectedHouseSum,
              qlxxEx,
              ...this.getSelectedOrgInfo,
            };

            if (this.firstFlow.thirdTypeData.netFlowdefCode === 'H999001') {
              params.qlxxExMhs.forEach((item) => {
                item.fwjg = '';
              });
            }

            //保存提取的业务信息生成新的业务号
            applicantSaveService.saveSqxx(params).then((resSqxx) => {
              if (resSqxx.success) {
                this.saveHtxxQlrYwr(resHtxx.data.data, bdcdyhs, resSqxx.data);
              } else {
                this.$message.warning(resSqxx.message);
              }
            });
          } else {
            this.$message.warning('请先选择户！！！');
          }
        }
      });
    },
    getHlist(row) {
      this.sfxf = row.sfxf;
      this.loading = true;
      getlpbInfo
        .getHlist({ zrzh: row.zrzh, bdcdjzmh: this.zParams.bdcdjzmh, qydm: getCookie('selectedAreaCode') })
        .then((res) => {
          this.loading = false;
          let data = res.data;
          console.log(data);
          if (data.length < 1) {
            this.$message.warning('楼盘表无数据！！！');
            this.arrData = [];
            this.$nextTick(() => {
              this.isShow = false;
              this.$refs.lpb.init(1, row.zl);
            });
          } else {
            this.zxxDetails = row;
            let newData = data;
            if (this.params.bdcdyh) {
              newData.forEach((item) => {
                if (item.bdcdyh === this.params.bdcdyh) {
                  item.isChoice = true;
                } else {
                  item.isChoice = false;
                }
              });
            }
            this.arrData = newData;
            this.$nextTick(() => {
              this.showlpb = true;
              this.$refs.lpb.init(2, row.zl);
            });
          }
        });
    },
    getSqbConfig() {
      let flowConfig = {};
      sqbConfig.forEach((config) => {
        if (config.code === this.firstFlow.thirdTypeData.netFlowdefCode) {
          //构建申请表左侧树
          flowConfig = config;
        }
      });
      return flowConfig;
    },
    async saveHtxxQlrYwr(htxx, bdcdyhs, resSqxx) {
      let qlrs = [];
      let ywrs = [];
      let qlrCategory = '';
      let ywrCategory = '';
      let ywh = '';
      let qlrCategorys = ['0', '2', '4', '7', '9', '16', '19'];
      let ywrCategorys = ['1', '3', '5', '6', '8', '10', '11', '12', '17', '18'];
      let isHblc = ['H999002', 'H999001'].indexOf(this.firstFlow.thirdTypeData.netFlowdefCode) >= 0; //转本位流程

      let flowConfig = this.getSqbConfig();
      if (isHblc) {
        ywh = resSqxx.ywh[1];
        console.log(flowConfig.data[1].config);
        flowConfig.data[1].config.forEach((item) => {
          console.log(item);
          if (qlrCategorys.indexOf(item.category) >= 0) {
            qlrCategory = item.category;
          }
        });
        flowConfig.data[1].config.forEach((item) => {
          if (ywrCategorys.indexOf(item.category) >= 0) {
            ywrCategory = item.category;
          }
        });
      } else {
        console.log(flowConfig.data);
        ywh = resSqxx.ywh[0];
        flowConfig.data[0].config.forEach((item) => {
          if (qlrCategorys.indexOf(item.category) >= 0) {
            qlrCategory = item.category;
          }
        });
        flowConfig.data[0].config.forEach((item) => {
          if (ywrCategorys.indexOf(item.category) >= 0) {
            ywrCategory = item.category;
          }
        });
      }
      htxx.qlrxx.forEach((qlr) => {
        qlrs.push({
          qlrmc: qlr.GFRMC,
          dz: qlr.DZ,
          zjh: qlr.ZJHM,

          zjzl: qlr.ZJLB,
          category: qlrCategory,
          dh: qlr.DHHM,
          ywh,
        });
      });
      htxx.ywrxx.forEach((ywr) => {
        ywrs.push({
          ywrmc: ywr.QYMC,
          dz: ywr.BGDZ,
          zjh: ywr.YYZZHM,
          zjzl: ywr.ZJLB,
          category: ywrCategory,
          dh: ywr.LXDH,
          frmc: ywr.FRDB,
          ywh,
        });
      });
      console.log(qlrs);
      console.log(ywrs);
      let qlrData = null;
      let ywrData = null;
      if (qlrs && qlrs.length) {
        qlrData = await applicantOperatorService.addRightHolderBatch({ bdcdyhs, qlrList: qlrs });
        if (!qlrData.success) {
          // eslint-disable-next-line no-console
          console.log(qlrData.message);
        }
      }
      // 有义务人则发送义务人请求
      if (ywrs && ywrs.length) {
        ywrData = await applicantOperatorService.addObligorBatch({ bdcdyhs, ywrList: ywrs });
        if (!ywrData.success) {
          // eslint-disable-next-line no-console
          console.log(ywrData.message);
        }
      }

      // 分开请求（并发请求后台会有事务方面的问题-不用，换分开单独请求）
      // 1、只调权利人 2、只调义务人 3、都调  （至少一个人）
      if (
        (qlrData && qlrData.success) ||
        (ywrData && ywrData.success) ||
        (qlrData && qlrData.success && ywrData && ywrData.success)
      ) {
        // 改变步骤条状态
        this.$store.commit('wysq-step/targetToStep', '楼盘表提取');
        // 路由跳转
        this.$router.push({
          name: this.nextItem.name,
          params: {
            ywh: resSqxx.wwywh,
            isNextBtnShow: true,
          },
        });
      }
    },
    getTdBdcdyhCfDy(selectedHouseSum) {
      if (selectedHouseSum.length <= 0) return this.$message.warning('请先选择户！！！');
      const _this = this;
      if (selectedHouseSum[0].bdcdyh.length < 28) {
        this.$message.error('不动产单元号异常');
        return;
      }
      _this.selectedHouseSum = selectedHouseSum;
      _this.onSubmit();
    },
    selectedHouse(selectedHouseSum) {
      console.log(selectedHouseSum);
      if (this.sfxf === '否' && this.firstFlow.thirdTypeData.netFlowdefCode === 'N100104') {
        // console.log('');
        this.$message.error('期楼盘禁止办理首次登记！请前往大厦关联后办理');
        return;
      }
      let fwidArray = selectedHouseSum.map((item) => {
        return item.fwid;
      });
      let arr = this.repeatElement1(fwidArray, this.containHtxx);
      console.log(arr);
      //  if(fwidArray)

      this.saveBdcInfo(selectedHouseSum);
    },
    repeatElement1(a, b) {
      let arr = [];
      for (let i = 0; i < a.length; i++) {
        if (b.indexOf(a[i]) !== -1 && arr.includes(a[i]) == false) {
          arr.push(a[i]);
        }
      }
      return arr;
    },
    createPcbh() {
      applicantSaveService
        .saveUserIdPcbh({
          userId: localDataUser.get().hasOwnProperty('jbrData')
            ? localDataUser.get().jbrData.id
            : localDataUser.get().id,
        })
        .then((res) => {
          if (res.success) {
            this.pchbForm.pcbh = res.data;
            this.getPcbhList();
            this.$message.success('获取成功');
          }
        });
    },
    getPcbhList() {
      applicantSaveService
        .getUserIdPcbh({
          userId: localDataUser.get().hasOwnProperty('jbrData')
            ? localDataUser.get().jbrData.id
            : localDataUser.get().id,
        })
        .then((res) => {
          if (res.success) {
            this.pcbhList = res.data;
          }
        });
    },
    // 保存不动产信息
    async saveBdcInfo(selectedHouseSum, participants, qlxxExtras) {
      // 接口对接
      selectedHouseSum.map((item) => {
        const updateHouse = {
          fwyt: item.ghyt || '10',
          ghyt: item.ghyt || '10', // 2022-03-24 ghyt字段添加
          fwcqmj: typeof item.jzmj === 'number' ? item.jzmj.toString() : item.jzmj,
          jzmj: typeof item.jzmj === 'number' ? item.jzmj.toString() : item.jzmj,
          ycjzmj: typeof item.ycjzmj === 'number' ? item.ycjzmj.toString() : item.ycjzmj,
          tnjzmj: typeof item.tnjzmj === 'number' ? item.tnjzmj.toString() : item.tnjzmj,
          ftjzmj: typeof item.ftjzmj === 'number' ? item.ftjzmj.toString() : item.ftjzmj,
          fttdmj: typeof item.fttdmj === 'number' ? item.fttdmj.toString() : item.fttdmj,
          dytdmj: typeof item.dytdmj === 'number' ? item.dytdmj.toString() : item.dytdmj,
          fwjg: this.formatDic('房屋结构', item.fwjg),
          fwlx: this.formatDic('房屋类型', item.fwlx),
          fwxz: this.formatDic('房屋性质', item.fwxz),
          szc: item.sjc,
          tdsyqx: item.tdsyqx,
          jgsj: item.jgrq ? new Date(item.jgrq).getTime() : '',
          tdqsrq: item.tdqsrq ? utils.dateFtt(item.tdqsrq, 'yyyy-MM-dd') : '',
          tdzzrq: item.tdzzrq ? utils.dateFtt(item.tdzzrq, 'yyyy-MM-dd') : '',
          tdyt: item.tdyt && item.tdyt.indexOf(',') > 0 ? item.tdyt.split(',')[0] : item.tdyt,
        };
        Object.assign(item, updateHouse);
      });
      if (selectedHouseSum.length) {
        let flowname = '';
        if (this.firstFlow.thirdTypeData.flowname) {
          flowname = this.firstFlow.thirdTypeData.flowname;
        } else {
          flowname = this.firstFlow.thirdTypeData.name;
        }
        //存勾选的所有户数据
        const params = {
          sfyddbl: 0, //是否移动端办理
          agentInfoId: this.userInfoData.dlrId || '',
          userId: localDataUser.get().hasOwnProperty('jbrData') ? localDataUser.get().jbrData.id : this.userInfoData.id, // 用户id
          sqrlx: this.userInfoData.userCategory, // 申请人类型
          bdclb: this.firstFlow.secondTypeData.bdclbEnum, // 不动产类别
          flowcode: this.firstFlow.thirdTypeData.flowCode,

          netFlowdefCode: this.firstFlow.thirdTypeData.netFlowdefCode,
          flowname: flowname,
          pcbh: this.pchbForm.pcbh,
          djdl: this.firstFlow.thirdTypeData.djdl,
          qllx: this.firstFlow.thirdTypeData.qllx,
          subFlowcode: this.firstFlow.fourTypeData.code,
          subFlowname: this.firstFlow.fourTypeData.name,
          taskCode: this.firstFlow.thirdTypeData.taskCode,
          taskHandleItem: this.firstFlow.thirdTypeData.taskHandleItem,
          pjlcmc: this.firstFlow.thirdTypeData.pjlcmc,
          qlxxExMhs: selectedHouseSum,
          userName: localDataUser.get().realName,
          qlxz: '',
          ...this.getSelectedOrgInfo,
        };
        if (qlxxExtras) {
          params.qlxxEx = qlxxExtras;
        }
        // if (this.firstFlow.thirdTypeData.netFlowdefCode === 'H999001') {
        //   params.qlxxExMhs.forEach((item) => {
        //     item.fwjg = '';
        //   });
        // }
        if (selectedHouseSum[0].bdcdyh.length < 28) {
          this.$message.error('不动产单元号异常，请前往权籍科处理');
        }

        //保存提取的业务信息生成新的业务号
        applicantSaveService.saveSqxx(params).then((res) => {
          this.submitLoading = false;
          this.pcbhVisible = false;
          if (res.success) {
            if (participants) {
              this.setDataCheck(selectedHouseSum[0], participants, res.data.wwywh, res.data.ywh);
            } else {
              this.$store.commit('wysq-step/targetToStep', '楼盘表提取');
              // 路由跳转
              this.$router.push({
                name: this.nextItem.name,
                params: {
                  ywh: res.data.wwywh,
                },
              });
            }
          } else {
            this.$message.warning(res.message);
          }
        });
      } else {
        this.$message.warning('请先选择户！！！');
      }
    },
    //下一步的状态
    nextBtnState(state) {
      this.isShow = state;
    },
    //银行项目名称接口查询
    selectFINANCIALInfo(val, valInit, index) {
      this.params.xmmc = val;
      let areacode = this.params.zlData.zlArea || '';
      //新增了一个querytype参数来用于模糊和精确查询，querytype==“jqcx”（精确查询），querytype == "mhcx" (模糊查询)
      let paramData = {
        xmmc: valInit ? valInit : this.params.xmmc,
        areacode,
        querytype: 'jqcx',
        nCurrent: 0,
        nSize: 10,
      };
      if (this.userInfoData.userCategory === 'KFS') {
        paramData.nSize = 10000;
      }
      applicantSaveService.getBdcXmxx(paramData).then((res) => {
        if (res.success && res.data) {
          if (this.userInfoData.userCategory === 'FINANCIAL' && !this.isShowlc) {
            this.zdOptions = res.data.page;
          } else {
            // 赋值建筑物名称
            this.zdOptions[index] && (this.zdOptions[index].jzwmc = res.data.page[0] ? res.data.page[0].jzwmc : '[]');
            this.zdOptions[index] && (this.zdOptions[index].jzwArr = res.data.page || []);
          }
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    selectJzwmc(val) {
      this.params.jzwmc = val;
      let newList = [];
      this.jzwmcOptionsAll.map((item) => {
        if (~item.indexOf(val) && newList.length < 11) {
          newList.push(item);
        }
      });
      if (newList.length !== 0) {
        this.jzwmcOptions = newList;
      } else {
        this.jzwmcOptions = [];
      }
    },
    // 判断数据是不是JSON格式
    isJsonObj(str) {
      try {
        if (typeof JSON.parse(str) === 'object') {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    formatDic(dicName, label) {
      if (!label) return '';
      // 如果是中文 那么我们就要做相应转换
      if (this.checkIsChinese(label)) {
        const dicValue = this.getValueByDicLable(dicName, label);
        return dicValue;
      } else {
        return label;
      }
    },
    // 根据字典名称获取对应的字典值
    getValueByDicLable(dicName, label) {
      const dic = dicObj.dic || [];
      let temp =
        dic &&
        dic.filter((currentValue) => {
          return currentValue.label === dicName;
        });
      if (temp && temp.length === 1 && temp[0].childrens) {
        return this.findDictionaryTree(temp[0].childrens, label);
      } else {
        return label;
      }
    },
    findDictionaryTree(data, label) {
      let value = '';
      for (let index in data) {
        if (String(data[index].label) === String(label)) {
          value = data[index].value;
          break;
        }
        if (data[index].hasOwnProperty('childrens')) {
          this.findDictionaryTree(data[index]['childrens'], label);
        }
      }
      return value;
    },
    // 检测是否为中文
    checkIsChinese(val) {
      const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g');
      if (reg.test(val)) {
        return true;
      } else {
        return false;
      }
    },
    // 处理产权核验数据
    async setDataCheck(qlxxExMhs, participant, wwywh, ywh) {
      if (!qlxxExMhs || !participant) return;
      // 不动产单元号
      const bdcdyhs = [qlxxExMhs.bdcdyh];
      // 权利人
      let qlrList = [];
      // 义务人
      let ywrList = [];
      let isHblc = this.firstFlow.thirdTypeData.netFlowdefCode === 'H999002'; //转本位流程
      let categoryList = ['4', '5', '6'];
      (participant || []).forEach((participant) => {
        if (isHblc && categoryList.includes(participant.category)) {
          participant.ywh = ywh[1];
        } else {
          participant.ywh = ywh[0];
        }
        participant.dh = participant.phone;
        participant = this.categoryJudge(participant);
        //是否有对应的相同权利人义务人
        let sameSqr = this.getSameParticipant(participant, ywh);
        if (sameSqr) {
          if (sameSqr.qlrmc) {
            qlrList.push(sameSqr);
          }
          // 义务人信息集合
          if (sameSqr.ywrmc) {
            ywrList.push(sameSqr);
          }
        }
        // 权利人信息集合
        if (participant.qlrmc) {
          qlrList.push(participant);
        }
        // 义务人信息集合
        if (participant.ywrmc) {
          ywrList.push(participant);
        }
      });
      let qlrData = null;
      let ywrData = null;
      // 有权利人则发送权利人请求
      if (qlrList && qlrList.length) {
        qlrData = await applicantOperatorService.addRightHolderBatch({ bdcdyhs, qlrList });
        if (!qlrData.success) {
          // eslint-disable-next-line no-console
          console.log(qlrData.message);
        }
      }
      // 有义务人则发送义务人请求
      if (ywrList && ywrList.length) {
        ywrData = await applicantOperatorService.addObligorBatch({ bdcdyhs, ywrList });
        if (!ywrData.success) {
          // eslint-disable-next-line no-console
          console.log(ywrData.message);
        }
      }
      // 分开请求（并发请求后台会有事务方面的问题-不用，换分开单独请求）
      // 1、只调权利人 2、只调义务人 3、都调  （至少一个人）
      if (
        (qlrData && qlrData.success) ||
        (ywrData && ywrData.success) ||
        (qlrData && qlrData.success && ywrData && ywrData.success)
      ) {
        // 改变步骤条状态
        this.$store.commit('wysq-step/targetToStep', '楼盘表提取');
        // 路由跳转
        this.$router.push({
          name: this.nextItem.name,
          params: {
            ywh: wwywh,
            isNextBtnShow: true,
          },
        });
      }
    },
    //判断相关人员信息是权利人还是义务人
    categoryJudge(participant) {
      let qlrCategory = ['0', '2', '4', '7', '9', '16', '19'];
      let ywrCategory = ['1', '3', '5', '6', '8', '10', '11', '12', '17', '18'];
      let qlr = qlrCategory.find((category) => category === participant.category);
      let ywr = ywrCategory.find((category) => category === participant.category);
      //申请人为权利人时
      if (qlr) {
        // 信息不完整，需要组装
        participant.qlrmc = participant.name;
      } else if (ywr) {
        // 信息不完整，需要组装
        participant.ywrmc = participant.name;
      }
      return participant;
    },
    //获取相同对应的人
    getSameParticipant(sqr, ywh) {
      let sameSqr = '';
      //判断是否操作有相同的申请人,并取出另一个的category
      if (!this.sameConfig) return;
      if (this.sameConfig['form0'] === sqr.category) {
        sameSqr = Object.assign({}, sqr);
        sameSqr.ywh = ywh[1];
        sameSqr.sqrType = sameSqr.sqrType === 'qlr' ? 'ywr' : 'qlr';
        sameSqr.category = this.sameConfig['form1'];
        if (sameSqr.qlrmc) {
          sameSqr.ywrmc = sameSqr.qlrmc;
          sameSqr.qlrmc = '';
        } else {
          sameSqr.qlrmc = sameSqr.ywrmc;
          sameSqr.ywrmc = '';
        }
      }
      return sameSqr;
    },
    uniqueSqrxx(sqrCategoryBg, participants, flowCode) {
      let sqrxx = participants || [];
      let bgData = [];
      let newsqrxx = [];
      sqrCategoryBg.forEach((item) => {
        if (item.code === flowCode) {
          bgData = item.bg;
        }
      });
      sqrxx &&
        sqrxx.length > 0 &&
        sqrxx.forEach((item) => {
          bgData.forEach((bg) => {
            if (bg['category' + item.category]) {
              //初始化申请人
              item.qlrlx = item.qlrlx || item.sqrlx;
              item.sfczr = item.sfczr || '1';
              item.zjzl = item.zjzl || '1';
              item.category = bg['category' + item.category];
              newsqrxx.push(item);
              console.log(item.category === '0');
              if (this.firstFlow.thirdTypeData.netFlowdefCode === 'H999002' && item.category === '0') {
                let copyitem = { ...item };
                copyitem.qlrlx = item.qlrlx || item.sqrlx;
                copyitem.category = 5;
                newsqrxx.push(copyitem);
              }
            }
          });
        });

      const changeArr = [];
      // 对申请人做一个保存
      sameSqr.forEach((sqrConfig) => {
        if (sqrConfig.code === flowCode) {
          newsqrxx.forEach((sqr) => {
            if (sqr.category === sqrConfig.same['form0']) {
              let newSqr = JSON.parse(JSON.stringify(sqr));
              newSqr.category = sqrConfig.same['form1'];
              changeArr.push(newSqr);
            }
          });
        }
      });
      return newsqrxx.concat(changeArr);
    },
  },
  mounted() {
    userInfo({ loginName: this.userInfoData.loginName }, this.userInfoData).then((res) => {
      this.userInfoData = res.data.userinfo ? res.data.userinfo : res.data.institutionalUser;
      let lcCode = ['N100201', 'N10020301', 'H999001', 'N100104', 'H999002', 'N200104', 'YCN200104'];
      this.$store.commit('wysq-step/targetToStep', '楼盘表提取');
      this.params.lcdm = this.firstFlow.thirdTypeData.netFlowdefCode;
      this.params.lcxlmc = this.firstFlow.thirdTypeData.name;
      console.log('this.userInfoData', this.userInfoData);
      this.isShowlc = ~lcCode.indexOf(this.firstFlow.thirdTypeData.netFlowdefCode) ? false : true;
      if (this.userInfoData.zdxxes) {
        //根据区域代码及宗地代码前六位进行匹配
        // let zdxxesArr = [];
        // zdxxesArr = (this.userInfoData.zdxxes || []).filter(
        //   ({ zddm }) => zddm.substring(0, 6) === this.params.zlData.zlArea
        // );
        // --适配甘南州
        // zdxxesArr =this.userInfoData.zdxxes || []).filter(({ zddm }) => {
        //   return zddm.substring(0, 4) === this.params.zlData.zlArea;
        //   // if (transferObj[zlArea]) {
        //   //   return zddm.substring(0, 6) === transferObj[zlArea];
        //   // } else if (this.params.zlData.zlArea === '620501') {
        //   //   return zddm.substring(0, 6) === '620502' || zddm.substring(0, 6) === '620503';
        //   // } else {
        //   //   return zddm.substring(0, 6) === this.params.zlData.zlArea;
        //   // }
        // });
        this.zdOptions = this.userInfoData.zdxxes;
        // if (this.zdOptions.length !== 0) {
        //   this.zdOptions.map((item, index) => {
        //     // this.selectFINANCIALInfo('', item.xmmc, index);
        //   });
        // }
      }
      // 避免银行用户偶尔进入楼盘表页面提取不到项目名称
      // if (this.userInfoData.userCategory === 'FINANCIAL') {
      //   ``;
      //   // this.selectFINANCIALInfo('');
      // }
    });
  },
};
</script>

<style scoped lang="scss">
@import 'src/pages/iebdc/styles/public';
@import 'src/pages/iebdc/styles/common-variables';

.show-house-table {
  padding: $spacing-large;

  /deep/ .el-select {
    width: 100%;
  }

  .main-footer {
    margin-top: $spacing-large;
  }
}

.el-pagination {
  text-align: center;
}

.legend-item {
  font-size: 14px;
  width: 80px;
  display: inline-block;
  text-align: center;
  margin: 0 10px;
  border-radius: 0.25em;
  padding: 5px 0;
  color: white;
  height: auto;
}

.pagination-col {
  margin-top: 20px;
  margin-bottom: 20px;

  .pagination {
    text-align: center;
  }
}
</style>
