import { render, staticRenderFns } from "./PoCheckbox.vue?vue&type=template&id=2f533378&scoped=true&"
import script from "./PoCheckbox.vue?vue&type=script&lang=js&"
export * from "./PoCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./PoCheckbox.vue?vue&type=style&index=0&id=2f533378&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f533378",
  null
  
)

export default component.exports