<template>
  <section class="po-checkbox public-font-size">
    <span v-if="checkboxData.name" class="checkbox-span">{{ checkboxData.name }}</span>
    <el-checkbox-group v-model="checkboxVal" size="middle" @change="change" style="display: inline-block;">
      <el-checkbox
        v-for="(checkbox, index) in checkboxData.list"
        :key="index"
        :label="checkbox.id ? checkbox : checkbox.val"
        class="public-font-size"
        border
        >{{ checkbox.name }}
        <span class="angle"><i class="el-icon el-icon-check"></i></span>
      </el-checkbox>
    </el-checkbox-group>
  </section>
</template>
<script>
export default {
  props: {
    checkboxData: Object
  },
  data() {
    return {
      checkboxVal: []
    };
  },
  methods: {
    checked(index) {
      alert(index);
    },
    change(e) {
      if (e.length > 1) {
        const cVal = e.pop();
        this.checkboxVal.push(cVal);
      }
      // console.log("this.checkboxVal",this.checkboxVal)
      this.$emit('change', this.checkboxVal);
    },
    clearVal(val) {
      this.checkboxVal = val;
    }
  },
  created() {
    this.checkboxVal = this.checkboxData.value;
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-checkbox__inner {
  display: none;
}
/deep/ .is-checked {
  .angle {
    border-bottom: 15px solid #409eff !important;
  }
}
.angle {
  position: absolute;
  height: 0;
  width: 0;
  bottom: 0;
  right: 0;
  margin-top: 8px;
  margin-left: -5px;
  border-bottom: 15px solid #e7e7e7;
  border-left: 15px solid transparent;
  /deep/ .el-icon {
    position: absolute;
    margin-top: 2px;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    color: #fff;
    margin-left: -2px;
    right: -3px;
  }
}
.el-checkbox {
  height: 26px !important;
  padding: 0 20px 0 10px;
  border-radius: unset;
  margin-right: 12px;
  /deep/ .el-checkbox__label {
    line-height: 20px;
  }
}
.checkbox-span {
  margin-right: 12px;
}
</style>
