<template>
  <el-dialog
    custom-class="dialog-unit"
    :title="`${form.shbw}详情信息`"
    :visible.sync="dialogUnitInfo"
    width="75%"
    :before-close="beforeClose"
  >
    <el-form ref="form" :model="form" label-width="140px" label-position="left">
      <el-col :span="12">
        <el-form-item label="不动产单元号">
          <el-input v-model="form.bdcdyh" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="坐落">
          <el-input v-model="form.zl" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="项目名称">
          <el-input v-model="form.xmmc" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="幢号">
          <el-input v-model="form.zh || computedZh" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="室号部位">
          <el-input v-model="form.shbw" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="建筑面积">
          <el-input :value="form.jzmj" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="套内建筑面积">
          <el-input :value="form.tnjzmj" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="分摊建筑面积">
          <el-input :value="form.ftjzmj" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="房屋用途">
          <dict-select v-model="form.ghyt" type="房屋用途" disabled></dict-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="房屋类型">
          <dict-select v-model="form.fwlx" type="房屋类型" disabled></dict-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="房屋性质">
          <dict-select v-model="form.fwxz" type="房屋性质" disabled></dict-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="所在层">
          <el-input v-model="form.sjc" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="总层数">
          <el-input v-model="form.zcs" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="房屋结构">
          <el-input v-if="!form.fwjg" v-model="form.fwjg" disabled></el-input>
          <dict-select v-else v-model="form.fwjg" type="房屋结构" disabled></dict-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="分摊土地面积">
          <el-input v-model="form.fttdmj" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="独用土地面积">
          <el-input v-model="form.dytdmj" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="竣工时间">
          <el-input v-model="form.jgsj" disabled></el-input>
        </el-form-item>
      </el-col>
    </el-form>
  </el-dialog>
</template>
<script>
import utils from '@/utils/utils';
export default {
  props: {
    dialogUnitInfo: Boolean,
    houseDetailsInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    houseDetailsInfo: function (val) {
      let formObj = JSON.parse(JSON.stringify(val));
      formObj.jgsj = utils.dateFtt(formObj.jgsj, 'yyyy-MM-dd hh:mm:ss');
      this.form = formObj;
      for (let key in formObj) {
        this.form[key] = formObj[key] || '';
      }
      console.log('====', this.form);
    },
  },
  filters: {
    dicFilter(data) {
      if (!data) {
        return '';
      }
    },
  },
  computed: {
    computedZh() {
      let zh = this.form.bdcdyh.substring(20, 24);
      // 去掉前置多余的0
      return zh.replace(/\b(0+)/gi, '');
    },
  },
  data() {
    return {
      activeName: 'td',
      bdcdyh: '',
      form: {
        bdcdyh: '',
        zl: '',
        xmmc: '',
        zh: '',
        shbw: '',
        jzmj: '',
        tnjzmj: '',
        ftjzmj: '',
        fwyt: '',
        fwlx: '',
        fwxz: '',
        sjc: '',
        zcs: '',
        fwjg: '',
        fttdmj: '',
        dytdmj: '',
        jgsj: '',
      },
    };
  },
  methods: {
    beforeClose() {
      this.$emit('dialogBeforeClose');
    },
  },
};
</script>
<style lang="scss">
.dialog-unit {
  overflow: hidden;
  .el-dialog__header {
    background-color: #0e90fe;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__close {
      color: #fff;
    }
  }
  /deep/ .el-form {
    .el-form-item {
      padding-right: 20px;
    }
  }
}
</style>
